import React from "react";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";

import * as pageStyles from "../global-styles/page.module.css";

export const Contact = (props: PageComponentProps) => {
  const title = "Contact Us";
  const description = "Contact information.";
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <p>
          Feel free to reach out with questions, comments, or for any other
          reason. Please send an email to:
        </p>
        <p>[...] at [...] dot org</p>
        <p>Replace both of the missing parts with "clairnote".</p>
        <p>(Obfuscated to thwart spam bots.)</p>
      </article>
    </Layout>
  );
};
