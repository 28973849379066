import { PageProps } from "gatsby";
import React from "react";

import { Contact } from "../../components/pages/Contact";

import { dnProps } from "../../js/utils";

const ContactPage = (props: PageProps) => (
  <Contact {...{ ...dnProps, ...props }} />
);

export default ContactPage;
